import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceViewModel } from 'app/shared/model/service-view-model';

@Injectable()
export class ServiceControlService {

  public toFormGroup(model: ServiceViewModel[], selections?: Set<string>): FormGroup {
    const group =  new FormGroup({});
    model.forEach(service => {
      if (selections === undefined || selections.has(service.name)) {
        this.addServiceToGroup(service, group);
      }
    });
    return group;
  }

  public addServiceToGroup(service: ServiceViewModel, group: FormGroup) {
    group.addControl(service.key, new FormControl(''));
    group.addControl(service.radioKey, new FormControl(''));
    group.addControl(service.freeTextKey, new FormControl(''));
    if (service.additionalDataFields) {
      service.additionalDataFields.forEach(additionalDataField => {
        group.addControl(additionalDataField.fieldKey, new FormControl(''));
      });
    }
  }
}
