import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.css']
})
export class HomeComponent implements OnInit {

  irdLogoPath =  '../../../assets/ird.svg';
  diaLogoPath =  '../../../assets/DIALogo.svg';
  westpacLogoPath =  '../../../assets/Westpac_logo.svg';
  tsbLogoPath =  '../../../assets/tsb-wide.png';
  fidelityLogoPath =  '../../../assets/fidelitylife_logo.png';
  asteronLogoPath =  '../../../assets/asteronlife_logo.png';
  ampLogoPath =  '../../../assets/ResLife_logo.png';
  partnersLogoPath =  '../../../assets/partnerslife_logo.png';
  publicTrustLogoPath =  '../../../assets/publictrust_logo.png';
  referer = '';

  get referrerName(): string {
    if (this.referer === 'westpac') {
      return 'Westpac';
    } else if (this.referer === 'ird') {
      return 'Inland Revenue';
    } else if (this.referer === 'govt') {
      return 'Passports Office';
    } else if (this.referer === 'tsb') {
      return 'TSB';
    } else if (this.referer === 'fidelity') {
      return 'Fidelity Life';
    } else if (this.referer === 'asteron') {
      return 'Asteron Life';
    } else if (this.referer === 'partners') {
      return 'Partners Life';
    }
    return 'someone';
  }


  get referrerLogo(): string {
    if (this.referer === 'westpac') {
      return this.westpacLogoPath;
    } else if (this.referer === 'ird') {
      return this.irdLogoPath;
    } else if (this.referer === 'govt') {
      return this.diaLogoPath;
    } else if (this.referer === 'tsb') {
      return this.tsbLogoPath;
    } else if (this.referer === 'fidelity') {
      return this.fidelityLogoPath;
    } else if (this.referer === 'asteron') {
      return this.asteronLogoPath;
    } else if (this.referer === 'partners') {
      return this.partnersLogoPath;
    }
    return undefined;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
  ) {
    if (environment.maintenance) {
      router.navigate(['/maintenance']);
    }
   }

  ngOnInit() {
    this.referer = this.route.snapshot.queryParamMap.get('ref') || '';
    if (this.referer) {
      this.analyticsService.trackEvent('Notify', 'Referral', this.referer);
    } else if (this.referer === '') {
      this.analyticsService.trackEvent('Notify', 'Direct');
    }
  }

  start() {
    this.analyticsService.trackEvent('Notify', 'Start', this.referer);
    this.router.navigate(['/notify/deceased']);
  }

  referral() {
    window.open('https://publictrust.co.nz/contact-us/contact-form-my-trove/', '_blank');
  }
}
