import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-links',
  templateUrl: './external-links.component.html',
  styleUrls: ['../../../styles/content.css']
})
export class ExternalLinksComponent {

}
