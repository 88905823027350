import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TroveComponent } from '../trove.component';
import { LogService } from 'app/services/log.service';
import { DocumentSelectionComponent } from '../document-selection/document-selection.component';
import { FormGroup } from '@angular/forms';
import { DocumentsService } from 'app/services/documents.service';
import { SupportingDocument, DocumentCategory } from '../model/supporting-document';

@Component({
  selector: 'app-documents-form',
  templateUrl: './documents-form.component.html',
  styleUrls: ['./documents-form.component.css']
})
export class DocumentsFormComponent extends TroveComponent implements AfterViewInit {

  @Output() backFromDocuments: EventEmitter<SupportingDocument[]> = new EventEmitter();
  @Output() submitDocuments: EventEmitter<SupportingDocument[]> = new EventEmitter();

  @ViewChild('contractOfService', {static: false}) contractOfService: DocumentSelectionComponent;
  @ViewChild('probate', {static: false}) probate: DocumentSelectionComponent;
  @ViewChild('will', {static: false}) will: DocumentSelectionComponent;
  @ViewChild('statutoryDeclaration', {static: false}) statutoryDeclaration: DocumentSelectionComponent;
  @ViewChild('otherDocument', {static: false}) otherDocument: DocumentSelectionComponent;

  contractOfServiceForm: FormGroup;
  formErrors = {
    'noServiceSelected': '',
    'save': ''
  };

  validationMessages = {
  };

  private viewInitComplete = false;

  get showWill(): boolean {
    if (this.viewInitComplete === false) {
      return true;
    } else if (this.probate && this.probate.status === 'wait') {
      return true;
    } else {
      return false;
    }
  }

  get tooBig(): boolean {
    return (this.contractOfService && this.contractOfService.status === 'too-big') ||
      (this.probate && this.probate.status === 'too-big') ||
      (this.will && this.will.status === 'too-big') ||
      (this.statutoryDeclaration && this.statutoryDeclaration.status === 'too-big');
  }

  constructor(
    private documentsService: DocumentsService,
    private logService: LogService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.logService.log(`DocumentsFormComponent ngAfterViewInit`);
      if (this.documentsService.documents) {
        this.initialise(this.contractOfService, this.documentsService.documents.filter(doc => doc.category === 'contractOfService').pop());
        this.initialise(this.probate, this.documentsService.documents.filter(doc => doc.category === 'probate').pop());
        this.initialise(this.will, this.documentsService.documents.filter(doc => doc.category === 'will').pop());
        this.initialise(this.statutoryDeclaration, this.documentsService.documents.filter(doc => doc.category === 'statutoryDeclaration').pop());
        this.initialise(this.otherDocument, this.documentsService.documents.filter(doc => doc.category === 'otherDocument').pop());
      }
      this.viewInitComplete = true;
  }


  public onBack() {
    this.backFromDocuments.emit(this.getDocuments());
  }

  public onSubmit() {
    this.submitDocuments.emit(this.getDocuments());
  }
  
  public categoryDescription(category: DocumentCategory, suffix?: string): string {
    return this.documentsService.categoryDescription(category, suffix);
  }

  private getDocuments(): SupportingDocument[] {
    const documents: SupportingDocument[] = [];
    documents.push(this.getDocument(this.contractOfService, 'contractOfService'));
    documents.push(this.getDocument(this.probate, 'probate'));
    if (this.will) {
      documents.push(this.getDocument(this.will, 'will'));
    }
    documents.push(this.getDocument(this.statutoryDeclaration, 'statutoryDeclaration'));
    documents.push(this.getDocument(this.otherDocument, 'otherDocument'));
    return documents;
  }

  private getDocument(component: DocumentSelectionComponent, category: DocumentCategory): SupportingDocument {
    return {
      status: component.status,
      category: category,
      name: component.filename || (category.charAt(0).toUpperCase() + category.substring(1)),
      data: component.data,
    };
  }

  private initialise(component: DocumentSelectionComponent, document: SupportingDocument) {
    if (component && document) {
      component.filename = document.name;
      component.data = document.data;
      component.status = document.status;
    }
  }
}
