import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TroveComponent } from '../trove.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LogService } from 'app/services/log.service';
import { Observable } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { NotifyRepository } from 'app/services/repository/notify-repository.service';
import * as Raven from 'raven-js';
import { CustomisationService } from 'app/services/customisation.service';
import { GovernmentService } from 'app/services/government.service';
import { ServiceViewModel } from '../model/service-view-model';
import { AdditionalData, Service } from '../model/service';
import { ServiceGroup, ServiceMetadata } from '../model/service-metadata';
import { ServiceControlService } from 'app/services/service-control.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export interface ServiceGroupItem {
  letter: string;
  names: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-services-form',
  templateUrl: './services-form.component.html',
  styleUrls: ['./services-form.component.css']
})
export class ServicesFormComponent extends TroveComponent implements OnInit {

  @Input() services: Service[];
  @Input() allowSelection: boolean;

  @Output() backFromServices: EventEmitter<Service[]> = new EventEmitter();
  @Output() submitServices: EventEmitter<Service[]> = new EventEmitter();

  @ViewChild('autocomplete', {static: false}) autocomplete: MatAutocomplete;
  
  submitted = false;
  servicesForm: FormGroup;

  stateForm: FormGroup = this.fb.group({
    serviceGroup: '',
  });

  formErrors = {
    'noServiceSelected': '',
    'save': '',
    'mutuallyExclusiveServicesSelected': ''
};

  serviceGroupItems: ServiceGroupItem[];

  serviceGroupOptions: Observable<ServiceGroupItem[]>;
  servicesViewModel: ServiceViewModel[];

  servicePanel: any;
  
  get showAdd(): boolean {
    const value = this.stateForm.get('serviceGroup').value;
    if (value && value === this.mostRecentSelection) {
      return true;
    }
    return false;
  }

  public serviceGroups: ServiceGroup[] = [];

  public get nonEmptyServiceGroups(): ServiceGroup[] {
    return this.serviceGroups.filter(group => {
      return this.servicesInGroup(group).length > 0;
    });
  }

  get hasSelections(): boolean {
    return this.allowSelection === false || this.selections.size > 0;
  }

  private selections: Set<string> = new Set<string>(); // Use a Set to store unique selections
  private mostRecentSelection: string;
  
  constructor(
    private notifyRepository: NotifyRepository,
    private serviceControlService: ServiceControlService,
    private governmentService: GovernmentService,
    private customisationService: CustomisationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private logService: LogService
  ) {
    super();
    this.serviceGroups = governmentService.serviceGroups;
  }

  ngOnInit(): void {
    this.logService.log(`ServicesFormComponent init`);
    if (this.allowSelection) {
      this.serviceGroupOptions = this.stateForm.get('serviceGroup')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterGroup(value))
        );
    }
    this.loadMetadata();
  }
  
  serviceDisplayName(serviceViewModel: ServiceViewModel): string {
    if (serviceViewModel.nameDisplaySuffix) {
      return `${serviceViewModel.name} ${serviceViewModel.nameDisplaySuffix}`;
    }
    return serviceViewModel.name;
  }

  servicesInGroup(group: ServiceGroup): ServiceViewModel[] {
    return this.servicesViewModel.filter(service => {
      return service.group === group && (this.allowSelection === false || this.selections.has(service.name));
    });
  }

  public serviceInstruction(serviceGroup: ServiceGroup): string {
    return this.governmentService.getInstructions(serviceGroup) || '';
  }

  public addSelection(){
    this.logService.log(`addSelection`);
    this.stateForm.get('serviceGroup').setValue('');
    this.selections.add(this.mostRecentSelection); // Add the value to the Set (duplicates automatically avoided)
    console.log('All selections:', Array.from(this.selections));
    // this.autocomplete.showPanel = false;
    const serviceViewModel = this.servicesViewModel.find(model => model.name === this.mostRecentSelection);
    this.serviceControlService.addServiceToGroup(serviceViewModel, this.servicesForm);
  }
  
  public onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedValue = event.option.value;
    this.mostRecentSelection = selectedValue;
  }
  
  onSubmit() {
    this.submitted = true;
    this.formErrors.save = '';
    this.formErrors.noServiceSelected = '';
    if (this.servicesForm.valid) {
      const services = this.getServices(this.servicesForm.value);
      if (services.length > 0) {
        const kiwisavers = services.filter(service => {
          const viewModel = this.servicesViewModel.find(model => model.code === service.code);
          return viewModel.group === 'KiwiSaver';
        });
        if (kiwisavers.length < 2) {
          this.submitServices.emit(services);
        } else {
          this.formErrors.mutuallyExclusiveServicesSelected = 'true';
        }
      } else {
        this.formErrors.noServiceSelected = 'true';
      }
    }
  }

  public onBack() {
    // For back it's ok if the form is invalid. We want the save the current state so it's reinstated
    // when the user goes forward from the previous form
    const formModel = this.servicesForm.value;
    const services = this.getServices(formModel);
    this.backFromServices.emit(services);
  }

   private loadMetadata() {
    this.logService.log(`About to load metadata`);
    this.notifyRepository.getServiceMetadata().then(metadata => {
      this.logService.log(`metadata contains ${metadata.length} services`);
      if (this.allowSelection) {
        this.serviceGroupItems =this.getServiceGroupItems(metadata);
      }
      this.servicesViewModel = this.governmentService.getServicesViewModel(metadata, this.customisationService.getCustomisationSelector());
      this.buildForm();
    }).catch(err => {
      this.logService.log('Load error ' + err);
      Raven.captureException(err);
      this.showErrorMessage(this.dialog, `Oops, we couldn't load relationships data. Please press 'back' then try again.`);
    });
  }

  private buildForm(): void {
    if (this.allowSelection && this.services && this.services.length > 0) {
      this.services.forEach(service => {
        this.selections.add(service.name);
      });
    }
    this.servicesForm = this.serviceControlService.toFormGroup(this.servicesViewModel, this.allowSelection  ? this.selections: undefined);
    this.servicesForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.logService.log('ServicesComponent value change ' + JSON.stringify(data));
      this.formErrors.noServiceSelected = '';
      this.services = this.getServices(this.servicesForm.value);
    });
  }

  private _filterGroup(value: string): ServiceGroupItem[] {
    if (value) {
      return this.serviceGroupItems
        .map(group => ({letter: group.letter, names: _filter(group.names, value)}))
        .filter(group => group.names.length > 0);
    }

    return this.serviceGroupItems;
  }

  getServices(formModel): Service[] {
    const selectedServices = this.servicesViewModel.filter(service => {
      if (this.allowSelection) {
        return (this.servicesForm.controls[service.key]);
      } else {
        return this.servicesForm.value[service.key].length > 0 ||
        (this.servicesForm.value[service.radioKey] !== '' && this.servicesForm.value[service.radioKey] !== 'didnotuse');
      }
    });

    return selectedServices.map(selectedService => {
      const service: Service = {
        code: selectedService.code,
        name : selectedService.name,
        identifier: formModel[selectedService.key] as string,
        selectedOption: formModel[selectedService.radioKey] as string,
        freeTextLabel: selectedService.freeTextLabel || '',
        freeTextValue: formModel[selectedService.freeTextKey] as string,
      };
      if (selectedService.additionalDataFields) {
        service.additionalData = selectedService.additionalDataFields.map(field => {
          const data: AdditionalData = {
            label: field.fieldLabel,
            value: formModel[field.fieldKey] as string
          };
          return data;
        });
      }
      return service;
    });
  }

  status(service: Service): string {
    const formModel = this.servicesForm.value;
    const updatedServices = this.getServices(formModel); // Concerned this may impact performance
    if (updatedServices) {
      const matchingService = updatedServices.find (s => {
        return s.code === service.code;
      });
      const matchingServiceModel = this.servicesViewModel.find (s => {
        return s.code === service.code;
      });
      if (matchingService) {
        if (matchingService.identifier && matchingService.identifier.length > 0) {
          const valid = matchingServiceModel.idValidator(matchingService.identifier);
          if (matchingServiceModel.additionalDataFields && matchingServiceModel.additionalDataFields.length > 0) {
            // Ensure all additional data fields have values
            const missingDataFields = matchingServiceModel.additionalDataFields.filter(field => {
              const additionalData = matchingService.additionalData.find(data => data.label === field.fieldLabel);
              return additionalData.value === undefined || additionalData.value.length < 1;
            });
            return valid && missingDataFields.length === 0 ? 'valid' : 'invalid';
          } else {
            return valid ? 'valid' : 'invalid';
          }
        } else if (matchingService.selectedOption !== '' && matchingService.selectedOption !== 'didnotuse') {
          return 'valid';
        } else if (matchingService.selectedOption === 'didnotuse') {
          return matchingService.selectedOption;
        }
      }
    }
    return 'other';
  }
  
  private getServiceGroupItems(metadata: ServiceMetadata[]): ServiceGroupItem[] {
    const grouped = metadata.reduce((previous, current) => {
      if (previous.get(current.group)) {
        previous.set(current.group, previous.get(current.group).concat(current.name))
      } else {
        previous.set(current.group, [current.name]);
      }
      return previous;
    }, new Map<string, string[]>());
    const serviceGroupItems = Array.from(grouped.keys()).map(key => {
      const serviceGroup: ServiceGroupItem = {
        letter: key,
        names: grouped.get(key),
      }
      return serviceGroup;
    });
    return serviceGroupItems;
  }

}
