import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { HarmonyAddress } from 'app/shared/model/harmony-address';
import { map } from 'rxjs/operators';
import { LogService } from './log.service';

@Injectable()
export class RapidAddressService {

  constructor(
    private http: HttpClient,
    private logService: LogService
  ) { }

  addressSearch(partialAddress: string): Observable<HarmonyAddress[]> {
    const url = environment.rapidAddressURL;
    this.logService.log('Calling ' + url);
    return this.http.post(url, {
      payload: [{ fullAddress: partialAddress }],
      sourceOfTruth: 'NZAD',
      featureOptions: { caseType: 'TITLE' }
    }, {
      headers: {
        'Authorization': environment.rapidAddressAuthorization
      }
    }).pipe(map(res => {
      // this.logService.log(`Response: ${JSON.stringify(res, null, 4)}`);
      const addresses: HarmonyAddress[] = [];
      for (const item of (res as any).payload) {
        addresses.push(item as HarmonyAddress);
      }
      return addresses;
    }));
  }


}
