import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['../../../styles/content.css']
})
export class MaintenanceComponent {

  constructor(private router: Router) {
    if (!environment.maintenance) {
      router.navigate(['/']);
    }
  }

}
