import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import { HomeComponent } from './content/home/home.component';
import { NotFoundComponent } from './content/not-found/not-found.component';
import { AboutComponent } from './content/about/about.component';
import { PrivacyComponent } from './content/privacy/privacy.component';
import { TermsComponent } from './content/terms/terms.component';
import { NewsletterComponent } from './content/newsletter/newsletter.component';
import { LearnMoreComponent } from './content/learn-more/learn-more.component';
import { DisclosureComponent } from './content/disclosure/disclosure.component';
import { ContactRoutingModule } from './content/contact/contact-routing.module';
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';
import { ExternalLinksComponent } from './content/external-links/external-links.component';

/**
 * Created by keithcoughtrey on 18/05/17.
 */
const routes: Routes = [
  { path: '',  component: HomeComponent },
  { path: 'about',  component: AboutComponent },
  { path: 'newsletter',  component: NewsletterComponent },
  { path: 'learn-more',  component: LearnMoreComponent },
  { path: 'privacy',  component: PrivacyComponent },
  { path: 'terms-and-conditions',  component: TermsComponent },
  { path: 'disclosure',  component: DisclosureComponent },
  { path: 'maintenance',  component: MaintenanceComponent },
  { path: 'links',  component: ExternalLinksComponent },
  { path: 'notify', loadChildren: () => import('./notify/notify.module').then(m => m.NotifyModule) },
  { path: 'portal', loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule) },
  { path: '**',  component: NotFoundComponent },
];
@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), ContactRoutingModule ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
