import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletter-subscribe',
  templateUrl: './newsletter-subscribe.component.html',
  styleUrls: ['../../../styles/content.css']
})
export class NewsletterSubscribeComponent {

}
