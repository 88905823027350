import { Component, OnInit, Inject, Input, EventEmitter, Output } from '@angular/core';
import { Deceased } from '../../notify/model/deceased';
import { DisplayDate } from 'app/shared/model/display-date';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { months } from '../../notify/model/date-fields';
import { CustomValidators } from '../validators/CustomValidators';
import { NotifyRepository } from '../../services/repository/notify-repository.service';
import { Router } from '@angular/router';
import { TroveComponent } from '../trove.component';
import { take, takeUntil, debounceTime } from 'rxjs/operators';
 // Needed to avoid Observable.throw is not a function
import * as Raven from 'raven-js';
import { MatDialog } from '@angular/material/dialog';
import { Person } from 'app/notify/model/person';
import { RapidAddressService } from 'app/services/rapid-address.service';
import { LogService } from 'app/services/log.service';
import { DeceasedData } from '../model/deceased-data';

@Component({
  selector: 'app-deceased-form',
  templateUrl: './deceased-form.component.html',
  styleUrls: ['./deceased-form.component.css']
})

export class DeceasedFormComponent extends TroveComponent implements OnInit {

  @Input() deceased: Deceased;
  @Input() requirePlaces: boolean;
  @Input() requireDateOfDeath: boolean;
  @Input() defaultIdentifier: string;
  @Input() submitLabel: string;

  @Output() submitDeceased: EventEmitter<DeceasedData> = new EventEmitter();

  submitted = false;
  deceasedForm: FormGroup;

  days = [''];
  months = months;
  birthYears = [''];
  deathYears = [''];

  formErrors = {
    'firstName': '',
    'middleName': '',
    'lastName': '',
    'placeOfBirth': '',
    'placeOfDeath': '',
    'dateOfBirth': '',
    'dateOfDeath': '',
    'fullAddress': '',
    'birthBeforeDeath': ''
  };

  validationMessages = {
    'firstName': {
      'required':      'Please provide this information'
    },
    'lastName': {
      'required':      'Please provide this information'
    },
    'placeOfBirth': {
      'required':      'Please provide this information'
    },
    'placeOfDeath': {
      'required':      'Please provide this information'
    },
    'dateOfBirth': {
      'daterequired':   'Please provide this information',
      'valid':   'Oops, that date doesn\'t exist in the month and year you\'ve picked.',
      'dateinpast': 'Please provide a date in the past.'
    },
    'dateOfDeath': {
      'daterequired':   'Please provide this information',
      'valid':   'Oops, that date doesn\'t exist in the month and year you\'ve picked.',
      'dateinpast': 'Please provide a date in the past.'
    },
    'fullAddress': {
      'required':      'Please provide this information'
    },
    'birthBeforeDeath': 'Please check the entered dates'
  };

  addresses: string[] = [];

  constructor(
    private fb: FormBuilder,
    private rapidAddressService: RapidAddressService,
    public dialog: MatDialog,
    private logService: LogService
  ) {
    super();
  }

  public ngOnInit() {
    for (let i = 1; i < 32; i++) {
      this.days.push(i.toString());
    }
    const year = new Date().getFullYear();
    for (let i = 0; i < 120; i++) {
      this.birthYears.push((year - i).toString());
    }
    this.deathYears = this.birthYears.slice(0, 21);
    if (!this.deceased.dateOfBirth) {
      this.deceased.dateOfBirth = new DisplayDate();
    }
    if (!this.deceased.dateOfDeath) {
      this.deceased.dateOfDeath = new DisplayDate();
    }
    this.buildForm();
  }

  public buildForm(): void {
    const dateOfBirthStatus = this.deceased.dateOfBirthStatus ? this.deceased.dateOfBirthStatus : this.deceased.birthDateApprox ? 'approximate' : 'exact';
    this.deceasedForm = this.fb.group({
      'firstName': [this.deceased.firstName, [Validators.required]],
      'middleName' : [this.deceased.middleName],
      'lastName' : [this.deceased.lastName, [Validators.required]],
      'dateOfBirth' : this.fb.group({
        'day' : [this.deceased.dateOfBirth.day],
        'month' : [this.deceased.dateOfBirth.month],
        'year' : [this.deceased.dateOfBirth.year],
      }, { validator: dateOfBirthStatus === 'unknown' ? [] : Validators.compose([CustomValidators.daterequired, CustomValidators.datevalid, CustomValidators.dateinpast])
      }),
      'dateOfBirthStatus' : [dateOfBirthStatus],
      'placeOfBirth' : [this.deceased.placeOfBirth, this.requirePlaces ? [Validators.required] : []],
      'deathDateApprox' : [this.deceased.deathDateApprox],
      'nzCitizen' : [this.deceased.nzCitizen],
      'nzPermanentResident' : [this.deceased.nzPermanentResident],
      'overseasDeath' : [this.deceased.overseasDeath],
      'dateOfDeath' : this.fb.group({
        'day' : [this.deceased.dateOfDeath.day],
        'month' : [this.deceased.dateOfDeath.month],
        'year' : [this.deceased.dateOfDeath.year],
      }, { validator: this.requireDateOfDeath ? Validators.compose([CustomValidators.daterequired, CustomValidators.datevalid, CustomValidators.dateinpast]) : Validators.compose([CustomValidators.datevalid, CustomValidators.dateinpast])
      }),
      'placeOfDeath' : [this.deceased.placeOfDeath, this.requirePlaces ? [Validators.required] : []],
      'fullAddress' : [this.getAddress(this.deceased), [Validators.required]], // Handles initialising addresses in old and new format
      'identifier' : [this.defaultIdentifier || ''],
    }, { validator: [CustomValidators.birthBeforeDeath]});

    this.deceasedForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => this.showValidationErrors(false));

    this.deceasedForm.get('dateOfBirthStatus')
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.updateOptionality(data as string)
      });


    this.deceasedForm.get('fullAddress')
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(300))
      .subscribe(data => this.updateAddressSuggestions(data as string));

    this.showValidationErrors(false); // (re)set validation messages now
  }

  public getAddress(deceased: Deceased): string {
    if (this.deceased.fullAddress) {
      return this.deceased.fullAddress;
    } else if (this.deceased.addressLine1) {
      return `${this.deceased.addressLine1} ${this.deceased.addressLine2 || ''} ${this.deceased.addressCity || ''} ${this.deceased.addressPostCode || ''}`;
    } else {
      return '';
    }
  }
  
  public onSubmit() {
    this.submitted = true;
    this.showValidationErrors(true);
    if (this.deceasedForm.valid) {
      const deceased = this.deceasedForm.value;
      const identifier = deceased.identifier;
      if (identifier) {
        deceased.identifier = undefined;
        this.submitDeceased.emit({ deceased: deceased, identifier: identifier });
      }
      this.submitDeceased.emit({ deceased: deceased });
    }

  }

  showValidationErrors(ignoreDirty: boolean) {
    if (!this.deceasedForm) { return; }
    const form = this.deceasedForm;
    
    this.formErrors.birthBeforeDeath = '';
    for (const field of Object.keys(this.formErrors)) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && (control.dirty || ignoreDirty) && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
    if (form.errors?.birthBeforeDeath && (form.touched || form.dirty) && !form.valid) {
      this.formErrors.birthBeforeDeath = this.validationMessages.birthBeforeDeath;
    }
  }

  clear() {
    this.deceasedForm.reset();
  }

  updateAddressSuggestions(text: string) {
    if (text && text.length > 4) {
      const matchingAddress = this.addresses.find (address => address === text);
      if (matchingAddress) {
        return;
      }
      this.rapidAddressService.addressSearch(text).pipe(take(1)).subscribe(addresses => {
        this.addresses = addresses.map(address => {
          return address.fullAddress;
        });
      }, err => {
        this.logService.error('Load addresses failed: ' + JSON.stringify(err, null, 4));
        Raven.captureException(err);
      });
    }
    this.addresses = [];
  }

  private updateOptionality(dateOfBirthStatus: string) {
    this.logService.log(`dateOfBirthStatus=${dateOfBirthStatus}`);
    const dateOfBirthControl = this.deceasedForm.get('dateOfBirth');
    if (dateOfBirthStatus === 'exact' || dateOfBirthStatus === 'approximate') {
      dateOfBirthControl.setValidators(Validators.compose([CustomValidators.daterequired, CustomValidators.datevalid, CustomValidators.dateinpast]));
    } else {
      dateOfBirthControl.clearValidators();
    }
    dateOfBirthControl.updateValueAndValidity({ emitEvent: false });

  }

}
