import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeathNotification } from '../../notify/model/death-notification';
import { Deceased } from '../../notify/model/deceased';
import { Notifier } from 'app/notify/model/notifier';
import { Service } from 'app/shared/model/service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { SupportingDocument } from 'app/shared/model/supporting-document';
import { Feedback } from 'app/shared/model/types';
import { Estate } from 'app/notify/model/estate';
import { LogService } from '../log.service';
import { ServiceMetadata } from 'app/shared/model/service-metadata';


@Injectable()
export class NotifyRepository {

  private notification: DeathNotification;
  private serviceMetadata: ServiceMetadata[];

  constructor(
    private http: HttpClient,
    private logService: LogService
  ) { }

  saveDeceased(deceased: Deceased): Observable<void> {
    if (this.notification) {
      this.notification.deceased = deceased;
    } else {
      this.notification = {
        deceased: deceased,
        services: [],
      };
    }
    return this.completeObservable();
  }

  saveNotifier(notifier: Notifier) {
    this.notification.notifier = notifier;
  }

  saveEstate(estate: Estate) {
    this.notification.estate = estate;
  }

  saveServices(services: Service[]): Observable<void> {
    this.notification.services = services;
    return this.completeObservable();
  }

  getSubmissionToken(documents: SupportingDocument[], captchaToken: string): Observable<any> {
    const url = environment.functionsURL + '/submissionToken';
    const startsWithDocuments = documents.map(document => {
      const startsWithDocument: SupportingDocument = {
        category: document.category,
        status: document.status,
        name: document.name,
        data: document.data ? document.data.substr(0, 1000) : undefined,
      };
      return startsWithDocument;
    });
    const body = {
      notification: {
        deceased: this.notification.deceased,
        notifier: this.notification.notifier,
        estate: this.notification.estate,
        services: this.notification.services,
        documents: startsWithDocuments,
      },
      captchaToken: captchaToken
    };
    this.logService.log(`Calling ${url} with body ${JSON.stringify(body, null, 4)}`);
    return this.http.post(url, body);
  }

  saveDeathNotification(documents: SupportingDocument[], submissionToken: string, feedback?: Feedback): Observable<any> {
    const url = environment.functionsURL + '/notification';
    const body = {
      notification: {
        deceased: this.notification.deceased,
        notifier: this.notification.notifier,
        estate: this.notification.estate,
        services: this.notification.services,
        documents: documents,
      },
      feedback: feedback,
      submissionToken: submissionToken
    };
    this.logService.log(`Calling ${url} with body ${JSON.stringify(body, null, 4)}`);
    return this.http.post(url, body);
  }

  getNotification(): DeathNotification {
    return this.notification;
  }

  async loadNotification(unguessableLinkToken: string): Promise<any> {
    const url = `${environment.functionsURL}/notification/${unguessableLinkToken}`;
    this.logService.log(`Calling ${url}`);
    const notificationPromise = this.http.get(url).toPromise() as Promise<DeathNotification>;
    const notification = await notificationPromise;
    this.logService.log(`Loaded ${JSON.stringify(notification, null, 4)}`);
    this.notification = notification;
    return notification;
  }

  public clearCache() {
    this.notification = undefined;
    this.serviceMetadata = undefined;
  }

  public async getServiceMetadata(): Promise<ServiceMetadata[]> {
    if (this.serviceMetadata === undefined) {
      this.serviceMetadata = await this._getServiceMetadata().toPromise();
    }
    return this.serviceMetadata;
  }

  private _getServiceMetadata(): Observable<ServiceMetadata[]> {
    const url = environment.functionsURL + '/serviceMetadata';
    this.logService.log(`Calling get ${url}`);
    return this.http.get(url) as Observable<ServiceMetadata[]>;
  }

  private completeObservable(): Observable<void> {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }
}
