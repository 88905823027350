import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TroveComponent } from '../trove.component';
import { LogService } from 'app/services/log.service';
import { ExtendedDeathNotification } from 'app/notify/model/extended-death-notification';
import { SupportingDocument } from '../model/supporting-document';
import { DocumentsService } from 'app/services/documents.service';

@Component({
  selector: 'app-notification-summary',
  templateUrl: './notification-summary.component.html',
  styleUrls: ['./notification-summary.component.css']
})
export class NotificationSummaryComponent  extends TroveComponent implements OnInit {

  
  @Input() deathNotification: ExtendedDeathNotification;
  @Input() documents: SupportingDocument[];
  
  constructor(
    private documentsService: DocumentsService,
    private logService: LogService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.logService.log(`NotificationSummaryComponent init`);
  }
  
  public documentDescription(document: SupportingDocument): string {
    if (document.status === 'wait') {
      const categoryDescription = this.documentsService.categoryDescription(document.category);
      return `Waiting for ${categoryDescription} to be available`;
    } else {
      return document.name;
    }
  }


}
