import { Injectable } from '@angular/core';
import { LogService } from './log.service';

export type CustomisationSelector = 'PublicTrust';

@Injectable({
  providedIn: 'root'
})
export class CustomisationService {

  private selector: CustomisationSelector;

  constructor(
    private logService: LogService,
  ) { }

  public selectCustomisation(selector: CustomisationSelector) {
    this.logService.log(`Selected ${selector} flow customisations`);
    this.selector = selector;
  }

  public getCustomisationSelector(): CustomisationSelector | undefined {
    return this.selector;
  }
}
