import { Injectable } from '@angular/core';
import { SupportingDocument, DocumentCategory } from 'app/shared/model/supporting-document';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LogService } from './log.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class DocumentsService {

  documents: SupportingDocument[] = [];

  constructor(
    private http: HttpClient,
    private fns: AngularFireFunctions,
    private logService: LogService
  ) { }

  public categoryDescription(category: DocumentCategory, suffix?: string): string {
    switch (category) {
      case 'contractOfService':
        return `Contract of Service${suffix || ''}`;
      case 'probate':
        return `Probate or Letters of Administration${suffix || ''}`;
      case 'will':
        return `Deceased's will${suffix || ''}`;
      case 'statutoryDeclaration':
        return `Statutory declaration${suffix || ''}`;
      case 'otherDocument':
        return `Other document${suffix || ''}`;
    }
  }

  public clearCache() {
    this.documents = [];
  }

  // Take the array of documents and return a new array with any documents containing data uploaded and the data replaced by the download url
  public async saveDocuments(submissionToken?: string): Promise<SupportingDocument[]> {
    const promises = this.documents.map(async doc => {
      if (doc.data) {
        const result = await this.saveDocument(doc, submissionToken);
        this.logService.log(`Save document was successful with result ${JSON.stringify(result, null, 4)}`);
        const savedDocument: SupportingDocument = {
          category: doc.category,
          status: doc.status,
          name: doc.name,
          // eslint-disable-next-line @typescript-eslint/dot-notation
          url: result['downloadUrl'] as string,
        };
        return savedDocument;
      } else {
        return doc;
      }
    });
    const documents = await Promise.all(promises);
    return documents;
  }
  
  private saveDocument(document: SupportingDocument, submissionToken?: string): Promise<object> {
    if (submissionToken) {
      const url = environment.functionsURL + `/document`;
      this.logService.log('Calling ' + url);
      return this.http.post(url, {
        document: document,
        submissionToken: submissionToken,
      }).toPromise();
    } else {
      const callable = this.fns.httpsCallable('saveDocument');
      return callable({
        document: document,
      }).toPromise();
    }
  }

}
