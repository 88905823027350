// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  maintenance: false,
  logging: true,
  firebase: {
    apiKey: 'AIzaSyBrg_TqfHZHIGgcSAe-kX4GWEX4TjNBbCc',
    authDomain: 'mytrove-dev.firebaseapp.com',
    databaseURL: 'https://mytrove-dev.firebaseio.com',
    projectId: 'mytrove-dev',
    storageBucket: 'mytrove-dev.appspot.com',
    messagingSenderId: '371449507009'
  },
  paymentKey: 'pk_test_PjIhKOGxkHOoiAzYbjuD6FA2',
  functionsURL: 'https://dev.mytrove.co.nz/api',
  // functionsURL: 'http://localhost:5000/mytrove-dev/us-central1',
  standardBackground: '#f3f0f0',
  portalBackground: '#fff',
  rapidAddressURL: 'https://hosted.mastersoftgroup.com/harmony/rest/nz/address',
  rapidAddressAuthorization: 'Basic bXRwcm9kdXNlcjppeDhiZWdLMVpabTlKUmRjSzNsZjlXQ01nTUc0NjdvQg==',
  uidAaronStewart: 'INdCcsMxFP',
  uidRossHughson: 'NANANANANA'
};
