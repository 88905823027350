import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { TroveComponent } from 'app/shared/trove.component';
import { ServiceViewModel } from 'app/shared/model/service-view-model';
import { Service } from 'app/shared/model/service';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { takeUntil } from 'rxjs/operators';
import { LogService } from 'app/services/log.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent extends TroveComponent implements OnInit {

  @Input() panel: MatExpansionPanel;
  @Input() form: FormGroup;
  @Input() service: ServiceViewModel;
  @Input() servicesData: Service[];
  idValidationMessage = '';

  get fieldLabel(): string {
    return `${this.service.fieldLabel}`;
  }

  get placeholder(): string {
    return `${this.service.fieldPlaceholder}`;
  }

  get options(): any[] {
    return this.service.options.filter(option => option.key !== 'didnotuse');
  }

  get notes(): string {
    return `${this.service.notes || ''}`;
  }

  get idIsValid(): boolean {
    if (this.service.idFormControlValidator) {
      const idControl = this.form.controls[this.service.key];
      if (idControl && idControl.value) {
        return idControl.valid;
      }
      return false;
    }
    return true;
  }

  constructor(
    public dialog: MatDialog,
    private logService: LogService
  ) {
    super();
  }

  ngOnInit() {
    const idControl = this.form.controls[this.service.key];
    this.addValidators(idControl);
    const radioControl = this.form.controls[this.service.radioKey];
    const freeTextControl = this.form.controls[this.service.freeTextKey];
    if (this.servicesData) {
      const serviceData = this.servicesData.find( service => {
        return service.code === this.service.code;
      });
      if (serviceData) {
        if (serviceData.identifier) {
          idControl.setValue(serviceData.identifier, { emitEvent: false });
          idControl.markAsDirty({onlySelf: true});
          this.updateValidationMessage(idControl);
        }
        if (serviceData.selectedOption) {
          radioControl.setValue(serviceData.selectedOption, { emitEvent: false });
        }
        if (serviceData.freeTextValue) {
          freeTextControl.setValue(serviceData.freeTextValue, { emitEvent: false });
        }
        if (serviceData.additionalData) {
          this.service.additionalDataFields.forEach((field, index) => {
            const additionalDataControl = this.form.controls[field.fieldKey];
            additionalDataControl.setValue(serviceData.additionalData[index].value, { emitEvent: false });
          });
        }
        this.updateAdditionalFieldValidators(serviceData.identifier);
      }
    }
    idControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.logService.log(`value change for ${this.service.key} to ${data}`);
      if (data.length > 0) {
        radioControl.setValue('', { emitEvent: false });
        this.updateValidationMessage(idControl);
      }
      this.updateAdditionalFieldValidators(data);
    });
    radioControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.logService.log(`value change for ${this.service.radioKey} to ${data}`);
      if (data.length > 0) {
        idControl.setValue('', { emitEvent: false });
        this.idValidationMessage = '';
      }
    });
  }

  showHint() {
    this.showMessage(this.dialog, this.service.fieldLabel, this.service.hint);
  }

  addValidators(idControl: AbstractControl) {
    if (this.service.idFormControlValidator) {
      idControl.setValidators(this.service.idFormControlValidator);
    }
  }
  updateValidationMessage(idControl: AbstractControl) {
    this.idValidationMessage = idControl.valid ? '' : `Please enter a valid ${this.service.fieldLabel}`;
  }

  updateAdditionalFieldValidators(data: string) {
    if (this.service.additionalDataFields) {
      this.service.additionalDataFields.forEach((field, index) => {
        const additionalDataControl = this.form.controls[field.fieldKey];
        additionalDataControl.setValidators(data.length > 0 ? [Validators.required] : []);
        additionalDataControl.updateValueAndValidity({ emitEvent: false });
      });
    }
  }
  // To simplify refactor this simply sets the corresponding radio button value but the radio group is hidden
  buttonPress(key: string) {
    const radioControl = this.form.controls[this.service.radioKey];
    radioControl.setValue(key, { emitEvent: true });
    this.panel.close();
  }

  hasMissingDataFields(): boolean {
    if (this.idIsValid && this.service.additionalDataFields && this.service.additionalDataFields.length > 0) {
      const missingDataFields = this.service.additionalDataFields.filter(field => {
        const additionalDataControl = this.form.controls[field.fieldKey];
        return additionalDataControl.value === undefined || additionalDataControl.value.length < 1;
      });
      return missingDataFields.length > 0;
    }
    return false;
  }

  clear() {
    const radioControl = this.form.controls[this.service.radioKey];
    radioControl.setValue('didnotuse', { emitEvent: true });
    this.panel.close();
  }
}
