import { Person } from './person';

export class Notifier {

    public notifyingPerson: Person;
    public notifierIsPR?: string;
    public personalRepresentitive?: Person;
    public relationship?: string;

}
