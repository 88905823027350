import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LogService } from 'app/services/log.service';
import { TroveComponent } from 'app/shared/trove.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-interested-party',
  templateUrl: './interested-party.component.html',
  styleUrls: ['./interested-party.component.css']
})
export class InterestedPartyComponent extends TroveComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Input() potentialPrimaryContact: boolean;

  // formGroup: FormGroup;

  formErrors = {
    'interestedPartyName': '',
    'interestedPartyRole': '',
    'interestedPartyPhone': '',
    'interestedPartyEmailAddress': '',
    'interestedPartyCompanyName': '',
  };

  validationMessages = {
    'interestedPartyName': {
      'required':      'Please provide this information'
    },
    'interestedPartyRole': {
      'required':      'Please provide this information'
    },
    'interestedPartyEmailAddress': {
      'required':      'Please provide this information',
      'email':   'Please enter a valid email address'
    },
    'interestedPartyPhone': {
      'required':      'Please provide this information'
    },
  };

  constructor(
    private logService: LogService
  ) {
    super();
  }

  ngOnInit(): void {
    // const group: any = {};
    // group['interestedPartyName'] = new FormControl('');
    // this.formGroup = new FormGroup(group)
    // this.form.addControl(`interestedParty${this.index}`, this.formGroup);
    this.formGroup
    .valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.logService.log(`Value changed ${JSON.stringify(data, null, 4)}`);
      this.showValidationErrors(false);
    });

  }

  showValidationErrors(ignoreDirty: boolean) {
    if (!this.formGroup) { return; }
    const form = this.formGroup;

    for (const field of Object.keys(this.formErrors)) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && (control.dirty || ignoreDirty) && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }


}
