import { Component, OnInit, Input } from '@angular/core';
import { LogService } from 'app/services/log.service';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['../../../styles/content.css']
})
export class ContentHeaderComponent implements OnInit {

  @Input() showLinks = false;
  @Input() activeLink = '';

  constructor(private logService: LogService) { }

  ngOnInit() {
    this.logService.log(`Active link is ${this.activeLink}`);
  }

}
