import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ContactComponent } from 'app/content/contact/contact.component';

/**
 * Created by keithcoughtrey on 12/10/17.
 */
const routes: Routes = [
  { path: 'contact',  component: ContactComponent },
];
@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class ContactRoutingModule {}
