import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LogService } from 'app/services/log.service';
import { RapidAddressService } from 'app/services/rapid-address.service';
import { TroveComponent } from '../trove.component';
import { Notifier } from 'app/notify/model/notifier';
import { DeceasedData } from '../model/deceased-data';
import { InterestedPartyComponent } from 'app/shared/interested-party/interested-party.component';
import { InterestedParty } from 'app/notify/model/interested-party';
import { CustomisationService } from 'app/services/customisation.service';
import { DeathNotification } from 'app/notify/model/death-notification';
import { Estate } from 'app/notify/model/estate';
import { Person } from 'app/notify/model/person';
import * as Raven from 'raven-js';
import { takeUntil, debounceTime, take } from 'rxjs/operators';
import { CustomValidators } from '../validators/CustomValidators';
import { NotifierData } from '../model/notifier-data';

@Component({
  selector: 'app-notifier-form',
  templateUrl: './notifier-form.component.html',
  styleUrls: ['./notifier-form.component.css']
})
export class NotifierFormComponent extends TroveComponent implements OnInit {

  @Input() notifier: Notifier;
  @Input() estate: Estate;
  
  @Output() backFromNotifier: EventEmitter<NotifierData> = new EventEmitter();
  @Output() submitNotifier: EventEmitter<NotifierData> = new EventEmitter();

  submitted = false;
  notifierForm: FormGroup;

  formErrors = {
    'reasonsForNotification': '',
    'radioInsuranceClaimant': '',
    'radioProbateStatus': '',
    'firstName': '',
    'lastName': '',
    'phone': '',
    'email': '',
    'companyName': '',
    'clientNumber': '',
    'bankReference': '',
    'radioCommunication': '',
    'mailingAddress': '',
    'radioRelationship': '',
    'prEmail': '',
  };

  validationMessages = {
    'reasonsForNotification': {
      'requireOneCheckboxToBeChecked':   'Please select at least one of the options'
    },
    'radioInsuranceClaimant': {
      'required':   'Please select one of the options'
    },
    'radioProbateStatus': {
      'required':   'Please select one of the options'
    },
    'firstName': {
      'required':      'Please provide this information'
    },
    'lastName': {
      'required':      'Please provide this information'
    },
    'phone': {
      'required':      'Please provide this information'
    },
    'email': {
      'required':   'Please provide this information',
      'email':   'Please enter a valid email address'
    },
    'companyName': {
      'required':      'Please provide this information'
    },
    'clientNumber': {
      'required':      'Please provide this information',
      'clientNumber':   'Please enter a valid client number'
    },
    'bankReference': {
      'required':      'Please provide this information',
      'bankReference':   'Please enter a valid bank reference'
    },
    'radioCommunication': {
      'required':   'Please provide this information'
    },
    'mailingAddress': {
      'required':      'Please provide this information'
    },
    'radioRelationship': {
      'required':   'Please select one of the options'
    },
    'prEmail': {
      'email':   'Please enter a valid email address'
    }
  };

  interestedParties: InterestedParty[] = [];

  addresses: string[] = [];

  @ViewChildren('interestedPartyComponents') interestedPartyComponents: QueryList<InterestedPartyComponent>;

  get firmNameLabel(): string {
    if (this.notifierForm && this.notifierForm.value.radioRelationship && this.notifierForm.value.radioRelationship === 'solicitor') {
      return 'Law or trustee firm name';
    }
    return 'Business or firm name';
  }

  get reasonForNotificationGiven(): boolean {
    const value = this.notifierForm.value;
    if (value && value.reasonsForNotification && (value.reasonsForNotification.isInsuranceClaim || value.reasonsForNotification.isEstateWindup)) {
      return true;
    }
    return false;
  }

  get isInsuranceClaim(): boolean {
    return this._isInsuranceClaim(this.notifierForm.value);
  }

  get isHandlingTheEstate(): boolean {
    return this._isHandlingTheEstate(this.notifierForm.value);
  }

  get isYourselfSectionVisible(): boolean {
    const value = this.notifierForm.value;
    if (value && (value.radioRelationship || (value.reasonsForNotification && value.reasonsForNotification.isInsuranceClaim && value.radioInsuranceClaimant && this.isHandlingTheEstate === false))) {
      return true;
    }
    return false;
  }

  get applyPublicTrustCustomisation(): boolean {
    return this.customisationService.getCustomisationSelector() === 'PublicTrust';
  }

  get addInterestedPartyEnabled(): boolean {
    if (this.interestedParties.length > 2) {
      return false;
    }
    if (this.interestedParties.length === 0) {
      return true;
    }
    const lastAddedParty = this.interestedFormGroup(this.interestedParties.length - 1);
    if (lastAddedParty.valid) {
      return true;
    }
    return false;
  }

  get deleteInterestedPartyEnabled(): boolean {
    return this.interestedParties.length > 0;
  }

  get notifierIsNotPrimaryContact(): boolean {
    if (this.notifierForm && this.notifierForm.value.radioRelationship &&
      (this.notifierForm.value.radioRelationship === 'solicitor' || this.notifierForm.value.radioRelationship === 'executor')) {
        return false;
    }
    return true;
  }

  constructor(
    private fb: FormBuilder,
    private rapidAddressService: RapidAddressService,
    private customisationService: CustomisationService,
    public dialog: MatDialog,
    private logService: LogService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.logService.log(`NotifierFormComponent init`);
    this.loadNotification();
  }

  loadNotification() {
    if (this.notifier === undefined) {
      this.notifier = new Notifier();
    }
    if (!this.notifier.notifyingPerson) {
      this.notifier.notifyingPerson = {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      };
    }
    if (this.notifier.notifyingPerson.mailingAddress) {
      this.addresses = [this.notifier.notifyingPerson.mailingAddress];
    }
    if (this.estate === undefined) {
      this.estate = new Estate();
    }
    this.buildForm(this.notifier, this.estate);
  }

  onSubmit() {
    this.submitted = true;
    this.showValidationErrors(true);
    this.interestedPartyComponents.forEach(cmp => {
      cmp.showValidationErrors(true);
    });
    if (this.notifierForm.valid) {
      const formModel = this.notifierForm.value;
      const notifier = this.getNotifierFromForm(formModel);
      const estate = this.getEstateFromForm(formModel);
      this.submitNotifier.emit({ notifier: notifier, estate: estate });
    }
  }

  public onBack() {
    // For back it's ok if the form is invalid. We want the save the current state so it's reinstated
    // when the user goes forward from the previous form
    const formModel = this.notifierForm.value;
    const notifier = this.getNotifierFromForm(formModel);
    const estate = this.getEstateFromForm(formModel);
    this.backFromNotifier.emit({ notifier: notifier, estate: estate });
  }

  buildForm(notifier: Notifier, estate: Estate): void {
    this.notifierForm = this.fb.group({
      'reasonsForNotification': this.fb.group({
        'isInsuranceClaim': [estate.isInsuranceClaim],
        'isEstateWindup': [estate.isEstateWindup],
      }, {
        validator: CustomValidators.requireCheckboxesToBeCheckedValidator
      }),
      'radioInsuranceClaimant' : [estate.insuranceClaimant, [Validators.required]],
      'radioProbateStatus' : [estate.probateStatus, [Validators.required]],
      'radioRelationship' : [notifier.relationship, [Validators.required]],
      'firstName': [notifier.notifyingPerson.firstName, [Validators.required]],
      'lastName' : [notifier.notifyingPerson.lastName, [Validators.required]],
      'phone' : [notifier.notifyingPerson.phone, [Validators.required]],
      'email' : [notifier.notifyingPerson.email, [Validators.required, Validators.email]],
      'companyName' : [notifier.notifyingPerson.companyName],
      'clientNumber' : [notifier.notifyingPerson.clientNumber, this.applyPublicTrustCustomisation ? [Validators.required, CustomValidators.publicTrustClientNumber] : []],
      'bankReference' : [notifier.notifyingPerson.bankReference, this.applyPublicTrustCustomisation ? [Validators.required, CustomValidators.publicTrustBankReference] : []],
      'radioCommunication' : [notifier.notifyingPerson.preferredCommunicationMethod, [Validators.required]],
      'mailingAddress': [notifier.notifyingPerson.mailingAddress, [Validators.required]],
    });
    this.addInterestedPartyGroups(estate.interestedParties || []);
    this.updateOptionality(this.notifierForm.value);
    if (notifier.relationship) {
      this.updateFirmOptionality(notifier.relationship);
    }

    this.notifierForm
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.logService.log(`Value changed ${JSON.stringify(data, null, 4)}`);
        this.updateOptionality(data);
        this.showValidationErrors(false);
      });

    this.notifierForm.get('radioRelationship')
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => this.updateFirmOptionality(data as string));

    this.notifierForm.get('mailingAddress')
      .valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(300))
      .subscribe(data => this.updateAddressSuggestions(data as string));

    this.showValidationErrors(false); // (re)set validation messages now
  }

  addInterestedPartyGroups(interestedParties: InterestedParty[]) {
    interestedParties.forEach((interestedParty, index) => {
      this.addGroup(index, interestedParty);
    });
    this.interestedParties = interestedParties;
  }

  showValidationErrors(ignoreDirty: boolean) {
    if (!this.notifierForm) { return; }
    const form = this.notifierForm;

    for (const field of Object.keys(this.formErrors)) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && (control.dirty || ignoreDirty) && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  updateOptionality(formModel: any) {
    const radioInsuranceClaimantControl = this.notifierForm.get('radioInsuranceClaimant');
    const radioProbateStatusControl = this.notifierForm.get('radioProbateStatus');
    const radioRelationshipControl = this.notifierForm.get('radioRelationship');
    if (this._isInsuranceClaim(formModel)) {
      radioInsuranceClaimantControl.setValidators([Validators.required]);
    } else {
      radioInsuranceClaimantControl.clearValidators();
    }
    radioInsuranceClaimantControl.updateValueAndValidity({ emitEvent: false });
    if (this._isHandlingTheEstate(formModel)) {
      radioProbateStatusControl.setValidators([Validators.required]);
      radioRelationshipControl.setValidators([Validators.required]);
    } else {
      radioProbateStatusControl.clearValidators();
      radioRelationshipControl.clearValidators();
    }
    radioProbateStatusControl.updateValueAndValidity({ emitEvent: false });
    radioRelationshipControl.updateValueAndValidity({ emitEvent: false });
  }

  updateFirmOptionality(relationship: string) {
    const companyNameControl = this.notifierForm.get('companyName');
    if (relationship === 'solicitor') {
      companyNameControl.setValidators([Validators.required]);
    } else {
      companyNameControl.clearValidators();
    }
    companyNameControl.updateValueAndValidity({ emitEvent: false });
  }

  updateAddressSuggestions(text: string) {
    if (text && text.length > 4) {
      const matchingAddress = this.addresses.find (address => address === text);
      if (matchingAddress) {
        return;
      }
      this.rapidAddressService.addressSearch(text).pipe(take(1)).subscribe(addresses => {
        this.addresses = addresses.map(address => {
          return address.fullAddress;
        });
      }, err => {
        this.logService.warn('Load addresses failed: ' + JSON.stringify(err, null, 4));
        Raven.captureException(err);
      });
    }
    this.addresses = [];
  }

  showMoreInfo(title: string, message: string) {
    this.showMessage(this.dialog, title, message);
  }

  addInterestedParty() {
    this.logService.log(`addInterestedParty`);
    this.interestedParties.push({
      name: '',
      email: '',
    });
    const index = this.interestedParties.length - 1;
    this.addGroup(index);

  }

  deleteInterestedParty() {
    this.logService.log(`deleteInterestedParty`);
    const index = this.interestedParties.length - 1;
    this.interestedParties.pop();
    this.notifierForm.removeControl(`interestedParty${index}`);

  }

  interestedFormGroup(index: number): FormGroup {
    return this.notifierForm.get(`interestedParty${index}`) as FormGroup;
  }

  referral() {
    window.open('https://publictrust.co.nz/contact-us/contact-form-my-trove/', '_blank');
  }

  private getNotifierFromForm(formModel): Notifier {
    const preferredCommunicationMethod = formModel.radioCommunication as string;
    const notifyingPerson: Person = {
      firstName: formModel.firstName as string,
      lastName: formModel.lastName as string,
      phone: formModel.phone as string,
      email: formModel.email as string,
      companyName: formModel.companyName as string,
      clientNumber: formModel.clientNumber as string,
      bankReference: formModel.bankReference as string,
      preferredCommunicationMethod: preferredCommunicationMethod,
      mailingAddress: formModel.mailingAddress as string,
    };
    const notifier: Notifier = {
      notifyingPerson: notifyingPerson,
      relationship: formModel.radioRelationship as string,
    };
    return notifier;
  }

  private getEstateFromForm(formModel): Estate {
    const parties = this.interestedParties.map((party, index) => {
      const name = formModel[`interestedParty${index}`].interestedPartyName;
      const role = formModel[`interestedParty${index}`].interestedPartyRole;
      const email = formModel[`interestedParty${index}`].interestedPartyEmailAddress;
      const phone = formModel[`interestedParty${index}`].interestedPartyPhone;
      const companyName = formModel[`interestedParty${index}`].interestedPartyCompanyName;
      const primaryEstateContact = formModel[`interestedParty${index}`].interestedPartyPrimaryEstateContact;
      const interestedParty: InterestedParty = {
        name: name,
        role: role,
        email: email,
        phone: phone,
        companyName: companyName,
        primaryEstateContact: primaryEstateContact,
      };
      return interestedParty;
    });
    return {
      probateStatus: formModel.radioProbateStatus,
      isEstateWindup: formModel.reasonsForNotification.isEstateWindup,
      isInsuranceClaim: formModel.reasonsForNotification.isInsuranceClaim,
      insuranceClaimant: formModel.radioInsuranceClaimant,
      interestedParties: parties,
    };
  }

  private _isInsuranceClaim(formModel: any): boolean {
    if (formModel && formModel.reasonsForNotification && formModel.reasonsForNotification.isInsuranceClaim) {
      return true;
    }
    return false;
  }

  private _isHandlingTheEstate(formModel: any): boolean {
    if (formModel && formModel.reasonsForNotification && (formModel.reasonsForNotification.isEstateWindup || formModel.radioInsuranceClaimant === 'estate')) {
      return true;
    }
    return false;
  }

  private addGroup(index: number, initialValue?: InterestedParty) {
    const group: any = {};
    group.interestedPartyName = new FormControl(initialValue ? initialValue.name || '' : '', [Validators.required]);
    group.interestedPartyRole = new FormControl(initialValue ? initialValue.role || '' : '', [Validators.required]);
    group.interestedPartyEmailAddress = new FormControl(initialValue ? initialValue.email || '' : '', [Validators.required, Validators.email]);
    group.interestedPartyPhone = new FormControl(initialValue ? initialValue.phone || '' : '');
    group.interestedPartyCompanyName = new FormControl(initialValue ? initialValue.companyName || '' : '');
    group.interestedPartyPrimaryEstateContact = new FormControl(initialValue ? initialValue.primaryEstateContact || false : false);
    const partyGroup = new FormGroup(group);
    this.notifierForm.addControl(`interestedParty${index}`, partyGroup);
  }
}
