import { ValidatorFn } from '@angular/forms';
import { ServiceGroup } from './service-metadata';

export type idValidatorFn = (id: string) => boolean;

export interface AdditionalDataField {
  fieldKey?: string;
  fieldLabel: string;
  fieldValueChoices: string[];
}

export class ServiceViewModel {

  code: string;
  key: string;
  radioKey: string;
  name: string;
  nameDisplaySuffix?: string;
  notes?: string;
  group: ServiceGroup;
  hint: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  idFormControlValidator: ValidatorFn;
  idValidator: idValidatorFn;
  options: any[];
  additionalDataFields?: AdditionalDataField[];
  freeTextKey: string;
  freeTextLabel?: string;
  freeTextHint?: string;

  constructor(values: {
    code: string,
    name: string,
    nameDisplaySuffix?: string;
    notes?: string;
    group: ServiceGroup,
    hint: string,
    fieldLabel: string,
    fieldPlaceholder: string,
    idFormControlValidator: ValidatorFn;
    idValidator: idValidatorFn,
    options: any[],
    additionalDataFields?: AdditionalDataField[]
    freeTextLabel?: string,
    freeTextHint?: string,

  }) {
    this.code = values.code;
    this.key = values.code + 'Number';
    this.radioKey = values.code + 'Radio';
    this.name = values.name;
    this.nameDisplaySuffix = values.nameDisplaySuffix;
    this.notes = values.notes;
    this.group = values.group;
    this.hint = values.hint;
    this.fieldLabel = values.fieldLabel;
    this.fieldPlaceholder = values.fieldPlaceholder;
    this.idFormControlValidator = values.idFormControlValidator;
    this.idValidator = values.idValidator;
    this.options = values.options;
    if (values.additionalDataFields) {
      this.additionalDataFields = values.additionalDataFields.map((field, index) => {
        return {
          fieldKey: `${values.code}_ADF${index + 1}`,
          fieldLabel: field.fieldLabel,
          fieldValueChoices: field.fieldValueChoices,
        };
      });
    }
    this.freeTextKey = values.code + 'FreeText';
    this.freeTextLabel = values.freeTextLabel;
    this.freeTextHint = values.freeTextHint;
  }
}
