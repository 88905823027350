import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// eslint-disable-next-line @typescript-eslint/ban-types
declare var gtag: Function;

type AnalyticsCategory = 'Notify' | 'Portal';
type AnalyticsAction = 'Referral' | 'Direct' | 'Start' | 'SignIn' | 'Submission';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  // Track page with google analytics
  public trackNavigation(newPath: string) {
    if (environment.production) {
      gtag('config', 'G-FF8K7Z0Z3G', {
        page_path: newPath
      });
    }
  }

  public trackEvent(eventCategory: AnalyticsCategory, eventAction: AnalyticsAction, eventLabel?: string, eventValue?: string) {
    if (environment.production) {
      gtag('event', eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue
      });
    }
  }

}
