import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent {

  get button1(): string {
    return this.data.button1 || 'OK';
  }

  get button2(): string {
    return this.data.button2;
  }

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public button1Click() {
    this.dialogRef.close(this.button1);
  }

  public button2Click() {
    this.dialogRef.close(this.button2);
  }

}
