import { CommonModule } from '@angular/common';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailService } from 'app/services/email.service';
import { ContactComponent } from 'app/content/contact/contact.component';
import * as Raven from 'raven-js';
import { ErrorHandler, NgModule, Injectable } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { MaterialModule } from 'app/material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule } from '@angular/common/http';

Raven
.config('https://8e4c3c458a164945823d048f50013f62@sentry.io/222554')
.install();

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err);
  }
}

@NgModule({
  declarations: [
    ContactComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
  ],
  exports: [
    ContactComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    EmailService
  ]
})
export class ContactModule { }

