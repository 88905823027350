import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentHeaderComponent } from 'app/content/content-header/content-header.component';
import { ContentFooterComponent } from 'app/content/content-footer/content-footer.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MaterialModule } from 'app/material.module';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { DynamicFormComponent } from 'app/shared/dynamic-form/dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DeceasedFormComponent } from './deceased-form/deceased-form.component';
import { NotifierFormComponent } from './notifier-form/notifier-form.component';
import { InterestedPartyComponent } from './interested-party/interested-party.component';
import { ServicesFormComponent } from './services-form/services-form.component';
import { NotificationSummaryComponent } from './notification-summary/notification-summary.component';
import { DocumentSelectionComponent } from './document-selection/document-selection.component';
import { DocumentsFormComponent } from './documents-form/documents-form.component';

@NgModule({
    declarations: [
      ContentHeaderComponent,
      ContentFooterComponent,
      DynamicFormComponent,
      MessageDialogComponent,
      MaintenanceComponent,
      DeceasedFormComponent,
      NotifierFormComponent,
      InterestedPartyComponent,
      ServicesFormComponent,
      NotificationSummaryComponent,
      DocumentSelectionComponent,
      DocumentsFormComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        ContentHeaderComponent,
        ContentFooterComponent,
        DynamicFormComponent,
        DeceasedFormComponent,
        NotifierFormComponent,
        ServicesFormComponent,
        NotificationSummaryComponent,
        DocumentSelectionComponent,
        DocumentsFormComponent,
        CommonModule
    ]
})
export class SharedModule { }
