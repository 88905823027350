import { AbstractControl, FormGroup, FormsModule, ValidatorFn } from '@angular/forms';
import { months } from '../../notify/model/date-fields';

/**
 * Provides a set of custom validators used by form controls.
 *
 * A validator is a function that processes a {\@link FormControl} or collection of
 * controls and returns a map of errors. A null map means that validation has passed.
 *
 * ### Example
 *
 * ```typescript
 * var loginControl = new FormControl("", Validators.required)
 * ```
 *
 * \@stable
 */
 export class CustomValidators {
  static datevalid(control: AbstractControl) {
      const day = control.value.day;
      const month = control.value.month;
      const year = control.value.year;
      if ((day === null || day.length === 0) && (month === null || month.length === 0) && (year === null || year.length === 0)) {
        return null;
      }
      if (day === null || day.length === 0 || month === null || month.length === 0 || year === null || year.length === 0) {
        return { valid: false };
      }
      const date = new Date(year, months.indexOf(month) - 1, day);
      if (date.getDate().toString() === day) {
        return null;
      }
      return { valid: false };
  }

  static dateinpast(control: AbstractControl) {
    const day = control.value.day;
    const month = control.value.month;
    const year = control.value.year;
    if (day === null || day.length === 0 || month === null || month.length === 0 || year === null || year.length === 0) {
      return null;
    }
    const date = new Date(year, months.indexOf(month) - 1, day);
    const now = new Date();
    if (now.getTime() > date.getTime()) {
      return null;
    }
    return { dateinpast: false };
  }

  static daterequired(control: AbstractControl) {
      const day = control.value.day;
      const month = control.value.month;
      const year = control.value.year;
      if (day === null || day.length === 0 || month === null || month.length === 0 || year === null || year.length === 0) {
         return { daterequired: false };
      }
      return null;
  }
  
  static birthBeforeDeath(control: AbstractControl) {
    let dateOfBirthStatus = control.value.dateOfBirthStatus;
    if (dateOfBirthStatus == 'unknown') {
      return null;
    }
    let day = control.value.dateOfBirth.day;
    let month = control.value.dateOfBirth.month;
    let year = control.value.dateOfBirth.year;
    if (day === null || day.length === 0 || month === null || month.length === 0 || year === null || year.length === 0) {
      return null;
    }
    const dateOfBirth = new Date(year, months.indexOf(month) - 1, day);
    day = control.value.dateOfDeath.day;
    month = control.value.dateOfDeath.month;
    year = control.value.dateOfDeath.year;
    if (day === null || day.length === 0 || month === null || month.length === 0 || year === null || year.length === 0) {
      return null;
    }
    const dateOfDeath = new Date(year, months.indexOf(month) - 1, day);
    if (dateOfBirth.getTime() <= dateOfDeath.getTime()) {
      return null;
    }
    return { birthBeforeDeath: true };
  }

  static optionalemail(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    const EMAIL_REGEXP
    = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    return EMAIL_REGEXP.test(control.value) ? null : { 'email': true };
  }

  static requireCheckboxesToBeCheckedValidator(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked ++;
      }
    });

    if (checked < 1) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }

    return null;
  }

  static irdNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.irdNumberValid(control.value) ? null : {'irdNumber' : false };
  }

  static irdNumberValid(irdNumber: string): boolean {
    const REGEXP = /(^[0-9]{2,3}-[0-9]{3}-[0-9]{3}$|^[0-9]{8,9}$)/g;
    if (!REGEXP.test(irdNumber)) {
      return false;
    }
    const characters: string = irdNumber.replace(/\D/g, '');
    const value = Number(characters);
    if (value < 10000000 || value > 150000000) {
      return false;
    }
    const digits = (characters.length < 9) ? '0' + characters.substring(0, 7) : characters.substring(0, 8);
    const checkDigit = Number(characters.charAt(characters.length - 1));
    const weights = [3, 2, 7, 6, 5, 4, 3, 2];
    let sum = weights.map( (weight, index) => {
      return weight * Number(digits.charAt(index));
    }).reduce((total, currentValue) => {
      return total + currentValue;
    }, 0);
    let remainder = sum % 11;
    let calculatedCheckDigit = (remainder === 0) ? 0 : 11 - remainder;
    if (calculatedCheckDigit > 9) {
      const secondaryWeights = [7, 4, 3, 2, 5, 2, 7, 6];
      sum = secondaryWeights.map( (weight, index) => {
        return weight * Number(digits.charAt(index));
      }).reduce((total, currentValue) => {
        return total + currentValue;
      }, 0);
      remainder = sum % 11;
      calculatedCheckDigit = (remainder === 0) ? 0 : 11 - remainder;
    }
    return checkDigit === calculatedCheckDigit;
  }

  static passportNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.passportNumberValid(control.value) ? null : {'passportNumber' : false };
  }

  static passportNumberValid(passportNumber: string): boolean {
    const PASSPORT_REGEXP = /^[A-Z]{1,2}[0-9]{6}$/gi;
    return PASSPORT_REGEXP.test(passportNumber);
  }

  static publicTrustClientNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.publicTrustClientNumberValid(control.value) ? null : {'clientNumber' : false };
  }

  static publicTrustClientNumberValid(clientNumber: string): boolean {
    const PUBLIC_TRUST_REGEXP = /^CLI[0-9]{8}$/gi;
    return PUBLIC_TRUST_REGEXP.test(clientNumber);
  }

  static publicTrustBankReference(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.publicTrustBankReferenceValid(control.value) ? null : {'bankReference' : false };
  }

  static publicTrustBankReferenceValid(clientNumber: string): boolean {
    const PUBLIC_TRUST_REGEXP = /^TCA[0-9]{7}$/gi;
    return PUBLIC_TRUST_REGEXP.test(clientNumber);
  }

  static bankAccountNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.bankAccountNumberValid(control.value) ? null : {'bankAccountNumber' : false };
  }

  static bankAccountNumberValid(bankAccountNumber: string): boolean {
    const BANK_REGEXP = /^[0-9]{2}\-[0-9]{4}-[0-9]{7}-[0-9]{2,3}$/gi;
    return BANK_REGEXP.test(bankAccountNumber);
  }

  static policyNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.policyNumberValid(control.value) ? null : {'policyNumber' : false };
  }

  static policyNumberValid(policyNumber: string): boolean {
    const POLICY_REGEXP = /^[A-Za-z0-9-]{4,20}$/gi;
    return POLICY_REGEXP.test(policyNumber);
  }

  static asteronPolicyNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.asteronPolicyNumberValid(control.value) ? null : {'policyNumber' : false };
  }

  static asteronPolicyNumberValid(policyNumber: string): boolean {
    const POLICY_REGEXP = /^[A-Za-z0-9-]{8}$/gi;
    return POLICY_REGEXP.test(policyNumber);
  }

  static nhiNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    return CustomValidators.nhiNumberValid(control.value) ? null : {'nhiNumber' : false };
  }

  static nhiNumberValid(nhiNumber: string): boolean {
    // AAANNNC where A is an alphabet character, but not ‘I’ or ‘O’, N is a number and C is a check digit
    const NHI_REGEXP = /^(?!.*[IO])[A-Z]{3}[0-9]{4}$/gi;
    if (!NHI_REGEXP.test(nhiNumber)) {
      return false;
    }

    const fistLetterValue = CustomValidators.getCharValue(nhiNumber, 0) * 7;
    const secondLetterValue = CustomValidators.getCharValue(nhiNumber, 1) * 6;
    const thirdLetterValue = CustomValidators.getCharValue(nhiNumber, 2) * 5;
    const firstDigitValue = CustomValidators.getNumber(nhiNumber, 3) * 4;
    const secondDigitValue = CustomValidators.getNumber(nhiNumber, 4) * 3;
    const thirdDigitValue = CustomValidators.getNumber(nhiNumber, 5) * 2;
    const sum = fistLetterValue + secondLetterValue + thirdLetterValue + firstDigitValue + secondDigitValue + thirdDigitValue;
    const remainder = sum % 11;
    const calculatedCheckDigit = 11 - remainder;
    const checkDigit = CustomValidators.getNumber(nhiNumber, 6);
    const valid = calculatedCheckDigit < 11 && (calculatedCheckDigit === 10 && checkDigit === 0) || (checkDigit === calculatedCheckDigit);
    return valid;
  }

  static nzMobileNumber(control: AbstractControl) {
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    const NZ_MOBILE_REGEXP = /^0{0,1}((20|21|22|24|27|28|29)\d{6,8})$/g;
    const numberWithoutWhitespace: string = control.value.replace(/\s/g, '');

    return NZ_MOBILE_REGEXP.test(numberWithoutWhitespace) ? null : { 'mobile': true };
  }

  private static getNumber(characters: string, index: number): number {
    return Number(characters.charAt(index));
  }

  private static getCharValue(characters: string, index: number): number {
    const result = characters.toUpperCase().charCodeAt(index) - 64;
    if (result > 14) {
      return result - 2;
    } else if (result > 8) {
      return result - 1;
    }
    return result;
  }

}
