import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import * as firebase from 'firebase/compat/app';

import { Observable ,  Subject } from 'rxjs';
import { environment } from '../environments/environment';

import * as Raven from 'raven-js';
import { AuthenticationService } from 'app/services/authentication.service';
import { AnalyticsService } from './services/analytics.service';
import { LogService } from './services/log.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'my trove';

  public user: Observable<firebase.default.User>;

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document,
    private logService: LogService
  ) {
    // https://blog.thecodecampus.de/angular-2-include-google-analytics-event-tracking/
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.analyticsService.trackNavigation(event.urlAfterRedirects);
        // Injecting document method comes from https://stackoverflow.com/a/41612521/2077204
        let backgroundColor = environment.standardBackground;
        if (this.isPortalAppPage(event.urlAfterRedirects)) {
          backgroundColor = environment.portalBackground;
        }
        this.document.body.style.backgroundColor = backgroundColor;
        // See https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change. Not concerned about back at the moment.
        if (!this.isSignInPage(event.urlAfterRedirects)) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  ngOnInit(): void {
    this.logService.log('AppComponent ngOnInit');
    this.authenticationService.getUser().subscribe( user => {
      if (user != null) {
        this.logService.log('App user id ' + user.uid);
        Raven.setUserContext({
          email: user.email,
          id: user.uid
        });
      } else {
        Raven.setUserContext();
      }
    });
  }


  isSignInPage(url: string): boolean {
    return url === '/' || url.endsWith('/welcome');
  }

  isPortalAppPage(url: string): boolean {
    return url.indexOf('portal') > 0;
  }

}
