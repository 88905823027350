import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LogService } from './log.service';

@Injectable()
export class EmailService {

    constructor(
      private http: HttpClient,
      private logService: LogService
    ) { }

    sendContactEmail(firstName: string, lastName: string, emailAddress: string, message: string, captchaToken: string): Observable<any> {
        const url = environment.functionsURL + '/sendContactEmail';
        this.logService.log('Calling ' + url);
        return this.http.post(url, {
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
          message: message,
          captchaToken: captchaToken
        });
    }

}
