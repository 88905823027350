import { Component, OnInit, Input, Output } from '@angular/core';
import { DocumentStatus } from 'app/shared/model/supporting-document';
import { TroveComponent } from 'app/shared/trove.component';
import { MatDialog } from '@angular/material/dialog';
import { LogService } from 'app/services/log.service';

const maxFileSize = 10485760;

@Component({
  selector: 'app-document-selection',
  templateUrl: './document-selection.component.html',
  styleUrls: ['./document-selection.component.css']
})
export class DocumentSelectionComponent extends TroveComponent {

  @Input() index: string;
  @Input() title: string;
  @Input() prompt: string;
  @Input() moreInfoLink: string;
  @Input() nonSelectionButtonText: string;
  @Input() nonSelectionDocumentStatus: DocumentStatus;

  @Output() public filename: string;
  @Output() public data: string;
  @Output() public status?: DocumentStatus;

  public get statusIcon(): string {
    switch (this.status) {
      case 'attached':
        return 'attach_file';
        break;
      case 'wait':
        return 'hourglass_empty';
        break;
      case 'nonexistent':
        return 'clear';
        break;
      default:
        return 'check_box_outline_blank';
        break;
    }
  }

  constructor(
    public dialog: MatDialog,
    private logService: LogService
  ) {
    super();
  }

  public changeListener($event): void {
    this.logService.log(`changeListener called for component with prompt ${this.prompt}`);
    const files = Array.from($event.target.files) as File[];
    if (files && files[0]) {
      const file = files[0];
      this.logService.log(`Selected file ${file.name} with size ${file.size} and type ${file.type}`);
      this.loadFileData(file).then(data => {
        if (this.hasAllowedMimeType(data)) {
          this.filename = file.name;
          if (data.length > maxFileSize) {
            this.status = 'too-big';
          } else {
            this.data = data;
            this.status = 'attached';
          }
        } else {
          this.showErrorMessage(this.dialog, 'The selected file was not of the expected type. Please select an image or PDF.');
        }
      });
    }
  }

  public onDelete() {
    this.filename = undefined;
    this.status = undefined;
  }

  public nonSelectionButtonClick() {
    this.filename = undefined;
    this.status = this.nonSelectionDocumentStatus;
    this.logService.log(`Status set to ${this.status} for index ${this.index}`);
  }

  private loadFileData(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        resolve(result as string);
      };
      reader.onerror = event => {
        this.logService.error(`File could not be read! Code ${event.target}`);
        reject(event.target);
      };
      reader.readAsDataURL(file);
    });

  }

  private hasAllowedMimeType(data: string): boolean {
    return data.startsWith('data:image/') || data.startsWith('data:application/pdf');
  }
}
