import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomisationService } from 'app/services/customisation.service';
import { LogService } from 'app/services/log.service';

@Component({
  selector: 'app-public-trust',
  templateUrl: './public-trust.component.html',
  styleUrls: ['./public-trust.component.css']
})
export class PublicTrustComponent implements OnInit {

  constructor(
    private customisationService: CustomisationService,
    private logService: LogService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.customisationService.selectCustomisation('PublicTrust');
    this.router.navigate(['']).catch(err => {
      this.logService.warn(err);
    });
  }

}
