import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailService } from 'app/services/email.service';
import { take, takeUntil } from 'rxjs/operators';

import * as Raven from 'raven-js';
import { TroveComponent } from 'app/shared/trove.component';
import { LogService } from 'app/services/log.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['../../../styles/content.css']
})
export class ContactComponent extends TroveComponent implements OnInit {

  // To change, search for the new address in Google maps and right-click on the place marker to see the lat, long
  options: google.maps.MapOptions = {
    center: {lat: -41.27027, lng: 174.77969},
    zoom: 15,
  };
  
  formErrors = {
    'submission': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    'message': '',
    'recaptchaReactive': '',
    'success': ''
  };
  validationMessages = {
    'firstName': {
      'required': 'Please provide your first name'
    },
    'lastName': {
      'required': 'Please provide your last name'
    },
    'email': {
      'email': 'Please enter a valid email address'
    },
    'message': {
      'required': 'Please enter a messgae'
    },
    'recaptchaReactive': {
      'required': 'Please complete the reCAPTCHA'
    }
  };

  apiLoaded: Observable<boolean>;
  submitted = false;

  contactForm: FormGroup;
  captchaToken: string;

  constructor(
    private fb: FormBuilder,
    private  emailService:  EmailService,
    private logService: LogService,
    private httpClient: HttpClient,
    ) {
    super();
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBur51xg8iN22gjCOE2pyvWozx_MaT6KOs', 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        );
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.contactForm = this.fb.group({
      'firstName': ['', [Validators.required]],
      'lastName' : ['', [Validators.required]],
      'email' : ['', [Validators.email]],
      'message' : ['', [Validators.required]],
      'recaptchaReactive' : ['', [Validators.required]]
    });

    this.contactForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => this.showValidationErrors(false));

    this.showValidationErrors(false); // (re)set validation messages now
  }

  showValidationErrors(ignoreDirty: boolean) {
    if (!this.contactForm) { return; }
    const form = this.contactForm;

    for (const field of Object.keys(this.formErrors)) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && (control.dirty || ignoreDirty) && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key of Object.keys(control.errors)) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  resolved(captchaResponse: string) {
    this.logService.log(`Resolved captcha with response ${captchaResponse}:`);
    this.captchaToken = captchaResponse;
  }

  onSubmit() {
    if (this.submitted) {
      return;
    }
    if (this.contactForm.valid) {
      this.submitted = true;
      const formModel = this.contactForm.value;
      this.emailService.sendContactEmail(
        formModel.firstName as string,
        formModel.lastName as string,
        formModel.email as string,
        formModel.message as string,
        this.captchaToken).pipe(take(1)).subscribe(result => {
          this.logService.log('Send email was successful');
          this.formErrors.success = 'success';
        }, err => {
          this.logService.log('Send email function failed: ' + JSON.stringify(err, null, 4));
          this.formErrors.submission = 'failed';
          this.submitted = false;
          Raven.captureException(err);
        });
    } else {
      this.showValidationErrors(true);
    }
  }
}
