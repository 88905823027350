import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['../../../styles/content.css']
})
export class NewsletterComponent {

  showNewsletter(issueLink) {
    if (issueLink.indexOf('campaign-archive2.com') > 0) {
      window.open(issueLink, '_blank');
    } else {
      window.open(`http://mailchi.mp/${issueLink}`, '_blank');
    }
  }
}
